<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu [hidden]="hiddenMenu" id="menuPrincipal" persistent="true" #menu contentId="main-content" type="overlay" side="start" menu-id="prin">
    <ion-header [translucent]="true">
        <ion-toolbar>
          <ion-title>
            <div class="logoDiv onlyDesktop">
              <img alt="logo" class="logo" width="180" height="50" src="./assets/img/main-logo.png" />
              <ion-icon (click)="closeMenu()" class="btnCloseMenu onlyMovil" slot="end" color="white" name="close-outline"></ion-icon>
            </div> 
          </ion-title>
        </ion-toolbar>
        <div class="logoDiv onlyMovil"><img alt="logo" width="234" height="65" class="logo" src="./assets/img/main-logo.png" /></div>
      </ion-header>
      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header class="headerMenuUser onlyMovil" *ngIf="authService.isLoggedIn">
            <div class="menuUser">
              <ion-avatar class="avatarFotoUser">
                  <img alt="photo" width="55" height="55" [src]="fotoUser" class="fotoUser" >
                </ion-avatar>
                <div class="data">
                    <ion-label>{{ nameUser }}</ion-label>
                    <img alt="flag" width="20" height="11" *ngIf="flag != ''" class="flag" src="{{flag}}" />
                  </div>
            </div>  
          </ion-list-header>

          <!-- <button *ngIf="authService.isLoggedIn && authService.storage.data.trial" (click)="subscribeNow()" class="buttonNext">{{ 'day_left_on_your_trial' | translate:{value:dayTrial} }}</button> -->

          <ion-menu-toggle auto-hide="false" class="{{ p.url == 'sync-accounts'?'logout':''}}" *ngFor="let p of pagesShow(); let i = index">
            <ion-item  (click)="selectedIndex = i" routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" [class.selected]="selectedIndex == i">
              <!-- *ngIf="!p.external" -->
              <img  width="25" height="25" alt="{{p.title}}" class="iconMenu" src="./assets/img/menu/{{p.icon}}" />
              <ion-label class="textMenu" *ngIf="p.title != 'technical_support'">{{ p.title | translate }}
                <ion-icon *ngIf="selectedIndex == i" slot="end" name="chevron-forward-outline"></ion-icon>
              </ion-label>
              <a href="{{p.url}}" *ngIf="p.title == 'technical_support'" class="textMenu" >{{ p.title | translate }}</a>
            </ion-item>
            <!-- <ion-item *ngIf="p.external" (click)="selectedIndex = i" routerDirection="root" (click)="onClickMenu(p.title)" lines="none" detail="false" [class.selected]="selectedIndex == i" style="cursor: pointer;">
                <img  width="25" height="25" alt="{{p.title}}" class="iconMenu" src="./assets/img/menu/{{p.icon}}" />
                <ion-label class="textMenu">{{ p.title | translate }}
                  <ion-icon *ngIf="selectedIndex == i" slot="end" name="chevron-forward-outline"></ion-icon>
                </ion-label>
              </ion-item> -->
          </ion-menu-toggle> 
          <ion-menu-toggle class="logout" (click)="logout()" *ngIf="authService.isLoggedIn && width < 993" auto-hide="false">
            <ion-item lines="none" detail="false">
              <img width="25" height="25" alt="close" class="iconMenu" src="./assets/img/menu/salir.png" />
              <ion-label class="textMenu" >{{ 'logout' | translate }}</ion-label>
            </ion-item>
          </ion-menu-toggle> 
        </ion-list>
        <div class="social onlyMovil">
            <mat-select  class="selectLang" #selectLang [value]="authService.getLang()">
                <mat-option (click)="setLang('es')" value="es">ESP</mat-option>
                <mat-option (click)="setLang('en')" value="en">ENG</mat-option>
            </mat-select>
            <a target="_blank" style="margin-left: auto;" *ngIf="parameters && parameters.youtube" rel="noopener" [href]="parameters?.youtube"><img alt="youtube" width="30" height="30" class="hashtagImg" src="./assets/img/icono-youtube-oscuro.png" /></a>
            <a target="_blank" *ngIf="parameters && parameters.tiktok" rel="noopener" [href]="parameters?.tiktok"><img alt="tiktok" width="30" height="30" class="hashtagImg" src="./assets/img/icono-tiktok-oscuro.png" /></a>
            <a target="_blank" *ngIf="parameters && parameters.instagram" rel="noopener" [href]="parameters?.instagram"><img alt="instagram" width="30" height="30" class="hashtagImg" src="./assets/img/icono-instagram-black.png" /></a>
            <a target="_blank" *ngIf="parameters && parameters.facebook" rel="noopener" [href]="parameters?.facebook"><img alt="facebook" width="30" height="30" class="hashtagImg" src="./assets/img/icono-Facebook-black.png" /></a>
            <a target="_blank" *ngIf="parameters && parameters.twitter" rel="noopener" [href]="parameters?.twitter"><img alt="twitter" width="30" height="30" class="hashtagImg" src="./assets/img/icono-twitter-black.png" /></a>
            <a href="mailto:{{parameters?.email}}"><img alt="email" width="30" height="30" class="hashtagImg" src="./assets/img/icono-mail-oscuro.png" /></a>
          </div>
      </ion-content> 
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
