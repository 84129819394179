import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoadDataService {

  constructor(
    private http: HttpClient,
    private storage: StorageService,
  ) { }

  load(filename: string):Promise<string>{
    return new Promise( ( resolve, reject  ) => {
      this.storage.getObject(filename).then( val => {
        if( val && val.value )
          resolve(val.value);
        this.http.get('./assets/data/'+filename, {responseType: 'text'}).subscribe( v => {
          this.storage.setObject(filename, { value: v });
          resolve(v);
        });
      }, error => {});
    }); 
  }

  loadImage(filename: string):Promise<string>{
    return new Promise( ( resolve, reject  ) => {
      this.storage.getObject(filename).then( val => {
        if( val && val.value )
          resolve(val.value);

        const img = new Image();
        img.crossOrigin = 'app.finplaninteligente';
        img.onloadeddata = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.height = img.naturalHeight;
          canvas.width = img.naturalWidth;
          ctx!.drawImage(img, 0, 0);
          let dataURL = canvas.toDataURL();
          this.storage.setObject(filename, { value: dataURL });
          resolve(dataURL);          
        };
  
        img.src = filename;
      }, error => {});
    }); 
  }

}
