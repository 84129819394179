import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { NotAuthGuard } from './guard/not-auth.guard';
import { PreloadingStrategyService } from './services/preloading-strategy.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule),
    canActivate: [NotAuthGuard.canActivate]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [NotAuthGuard.canActivate]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard.canActivate]
  },
  /* {
    path: 'bank-accounts',
    loadChildren: () => import('./pages/bank-accounts/bank-accounts.module').then( m => m.BankAccountsPageModule),
    canActivate: [AuthGuard]
  }, */
  {
    path: 'budget',
    loadChildren: () => import('./pages/budget/budget.module').then( m => m.BudgetPageModule),
    canActivate: [AuthGuard.canActivate]
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacity/privacity.module').then( m => m.PrivacityPageModule)
  },
  /* {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs/faqs.module').then( m => m.FaqsPageModule)
  }, */
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'signup1',
    loadChildren: () => import('./pages/auth/singup1/singup1.module').then( m => m.Singup1PageModule),
    canActivate: [NotAuthGuard.canActivate]
  },
  {
    path: 'signup2',
    loadChildren: () => import('./pages/auth/singup2/singup2.module').then( m => m.Singup2PageModule),
    canActivate: [AuthGuard.canActivate]
  },
  {
    path: 'signup3',
    loadChildren: () => import('./pages/auth/singup3/singup3.module').then( m => m.Singup3PageModule),
    canActivate: [AuthGuard.canActivate]
  },
  {
    path: 'signup4',
    loadChildren: () => import('./pages/auth/singup4/singup4.module').then( m => m.Singup4PageModule),
    canActivate: [AuthGuard.canActivate]
  },
  {
    path: 'signup5',
    loadChildren: () => import('./pages/auth/singup5/singup5.module').then( m => m.Singup5PageModule),
    canActivate: [AuthGuard.canActivate]
  },
  {
    path: 'signup6',
    loadChildren: () => import('./pages/auth/singup6/singup6.module').then( m => m.Singup6PageModule),
    canActivate: [AuthGuard.canActivate]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate: [AuthGuard.canActivate]
  },
  {
    path: 'recover-password',
    loadChildren: () => import('./pages/recover-password/recover-password.module').then( m => m.RecoverPasswordPageModule),
    canActivate: [NotAuthGuard.canActivate]
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./pages/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule),
    canActivate: [NotAuthGuard.canActivate]
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule),
    canActivate: [AuthGuard.canActivate]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard.canActivate]
  },
  {
    path: 'transaction',
    loadChildren: () => import('./pages/transaction/transaction.module').then( m => m.TransactionPageModule),
    canActivate: [AuthGuard.canActivate]
  },
  /* {
    path: 'rules',
    loadChildren: () => import('./pages/rules/rules.module').then( m => m.RulesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pages/pricing/pricing.module').then( m => m.PricingPageModule),
    canActivate: [NotAuthGuard]
  }, */
  {
    path: 'payment/:type',
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule),
    canActivate: [AuthGuard.canActivate]
  },
  /* {
    path: 'subscription',
    loadChildren: () => import('./pages/subcription/subcription.module').then( m => m.SubcriptionPageModule),
    canActivate: [AuthGuard]
  }, */
  {
    path: 'plan',
    loadChildren: () => import('./pages/plan/plan.module').then( m => m.PlanPageModule),
    canActivate: [AuthGuard.canActivate]
  },
  {
    path: 'warn',
    loadChildren: () => import('./pages/warn/warn.module').then( m => m.WarnPageModule)
  },
  {
    path: 'redirect',
    loadChildren: () => import('./pages/redirect/redirect.module').then( m => m.RedirectPageModule)
  },
  {
    path: 'android-install',
    loadChildren: () => import('./pages/android-install/android-install.module').then( m => m.AndroidInstallPageModule)
  },
  {
    path: 'ios-install',
    loadChildren: () => import('./pages/ios-install/ios-install.module').then( m => m.IosInstallPageModule)
  },
  {
    path: 'eresfpi',
    loadChildren: () => import('./pages/blogs/blogs.module').then( m => m.BlogsPageModule)
  },
  {
    path: 'unsubscribe/:email',
    loadChildren: () => import('./pages/unsubscribe/unsubscribe.module').then( m => m.UnsubscribePageModule)
  },
  {
    path: ':id/logo.jpeg',
    loadChildren: () => import('./pages/invisible-image/invisible-image.module').then( m => m.InvisibleImagePageModule)
  },
   {
    path: 'balance',
    loadChildren: () => import('./pages/balance/balance.module').then( m => m.BalancePageModule),
    canActivate: [AuthGuard.canActivate]
  },
  /*{
    path: 'debts',
    loadChildren: () => import('./pages/debts/debts.module').then( m => m.DebtsPageModule)
  }, */
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadingStrategyService })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
