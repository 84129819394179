import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { AuthService } from './auth.service';
import { ParametersApi } from './api/parameters.api';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  static data:any;
  public patametersSubject: BehaviorSubject<any> = new BehaviorSubject({ 
    ...environment.parameters,
    "time": new Date().getTime()
  });

  constructor(
    private storageService: StorageService,
    private parametersApi: ParametersApi,
  ) { 
    let self = this;
      this.parametersApi.get().subscribe({
        next: (val: any)=>{
        if(val.data ){
          if (val.data.time) {
            let time1 = val.data.time.replace(/ /g,"T");
          val.data.time = new Date(time1).getTime();
          }
          self.setData(val.data)
          self.storageService.setObject('parameters', val.data);
        }
        
      },
      error: (e: any) => {
        self.storageService.getObject('parameters').then( val => {
          self.setData(( val )? val : environment.parameters);
        }, err => {
          self.setData(environment.parameters);
        })
      }});
  }

  setData(data: any){
    ParametersService.data = data;
    this.patametersSubject.next(data);
  }

  sync( ){   
    return this.patametersSubject;
  } 

}
