import { Injectable } from '@angular/core';
import { UtilsApi } from './utils.api';

@Injectable({
  providedIn: 'root'
})

export class ParametersApi {

    constructor(
        public utilsApi: UtilsApi,
    ) { }

    get(){
        return this.utilsApi.get(false, '/api/parameters');
    }
}