import { Injectable } from '@angular/core';
import { UtilsApi } from './utils.api';

@Injectable({
  providedIn: 'root'
})

export class TransactionApi {

    constructor(
        public utilsApi: UtilsApi,
    ) { }

    sync( query:any = {} ){
        return this.utilsApi.get(true, '/api/transactionsync', query=query);
    }

    get( query:any = {} ){
        return this.utilsApi.get(true, '/api/transactions', query=query);
    }

    create( body:any ){
        return this.utilsApi.post(true, '/api/transactions', body=body);
    }

    update( body:any ){
        return this.utilsApi.put(true, '/api/transactions', body=body);
    }

    delete( query:any ){
        return this.utilsApi.delete(true, '/api/transactions', query=query);
    }

    move( body:any ){
        return this.utilsApi.post(true, '/api/movetrn', body=body);
    }
}
