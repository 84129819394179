import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NavController  } from '@ionic/angular';

/*@Injectable({
  providedIn: 'root'
})*/
export namespace NotAuthGuard {
  /*constructor(
    private navCtrl:NavController,
    private authService: AuthService,
  ) {}*/

  export const canActivate = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean => {
      return new Promise( (resolve, reject) => {   
        const authService = inject(AuthService); 
        const navCtrl = inject(NavController);
        
        authService.getToken().then(() => {
          let currentUser = authService.isLoggedIn;
          let authorization = authService.getAuthorization();
          
          if ( currentUser && authorization ){ 
            navCtrl.navigateRoot(['dashboard']);
            return resolve(false);
          }

         return resolve(true);
        },
        (err)=>{
          return resolve(true);
        });
    });
  }
}
