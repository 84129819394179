import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AlertService } from '../services/alert.service';
import { NotificationsApi } from '../services/api/notifications.api';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private afMessaging: AngularFireMessaging,
    private authService: AuthService,
    private notificationsApi: NotificationsApi,
    private alertService: AlertService,
    private updates: SwUpdate,
    ) {
      this.updates.versionUpdates.subscribe({
        next: event => {
        console.log('current version is', event);
        console.log('available version is', event);
        if (event.type == "VERSION_READY") {
          this.updates.activateUpdate().then(
            () => document.location.reload(), 
            error => console.log(error));
        }
      }
    });
  }

  register(){
    this.afMessaging.requestToken
    .subscribe(
      {next: (token) => {
        this.saveToken(token);
       },
      error: (error) => {
        console.error(error);
      }});
  }

  listen() {
    this.afMessaging.messages
      .subscribe({
        next:(message:any) => { 
        this.alertService.presentToast(message.notification.body);
        this.authService.setUser({"countNotification": parseInt(message.data.countNotification) });
      }, error: e => console.log(e)});
  }

  deleteToken() {
    this.afMessaging.getToken
      .pipe(mergeMap(token => this.afMessaging.deleteToken(<string>token)))
      .subscribe(
        (token) => { console.log('Token deleted!'); },
      );
  }

  saveToken(token: string | null){
    if( !token ) return;

    this.authService.getFirebase().then( v => {
      let params = {
        "token": token,
        "oldToken": ""
      }
      if( v.value )
        params["oldToken"] = v.value;
      
      this.authService.setFirebase(token);
      this.notificationsApi.setToken(params).subscribe({ next: (value: any) => {}, error:(err: any) => console.log(err) });
    }).catch( e => console.log(e) );
  }
}
