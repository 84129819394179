import { Injectable } from '@angular/core';
import { UtilsApi } from './utils.api';

@Injectable({
  providedIn: 'root'
})

export class NotificationsApi {

    constructor(
        public utilsApi: UtilsApi,
    ) { }

    get( query:any = {} ){
        return this.utilsApi.get(true, '/api/notifications', query=query);
    }

    read( body:any = {} ){
        return this.utilsApi.put(true, '/api/notifications', body=body);
    }

    setToken( body:any = {} ){
        return this.utilsApi.post(true, '/api/notifications', body=body);
    }

    readAll() {
        return this.utilsApi.post(true, '/api/notifications/readall');
    }
}
