import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toastController: ToastController) { }

  async presentToast(message: any) {
    const toast = await this.toastController.create({
      message: message,
      duration: 15000,
      position: 'bottom',
      buttons: [
        {
          text: 'X',
          role: 'cancel'
        }
      ]
     // showCloseButton:true
    });
    toast.present();
  }
}
