import { Injectable } from '@angular/core';
import { UtilsApi } from './utils.api';

@Injectable({
  providedIn: 'root'
})

export class UserApi {

    constructor(
        private utilsApi: UtilsApi,
    ) { }
    
    get( ){
        return this.utilsApi.get(true, '/api/clients');
    }

    register( body:any, query:any = {} ){
        return this.utilsApi.post(false, '/api/register', body=body, query=query);
    }

    update( body:any, query:any = {} ) {
        return this.utilsApi.put(true, '/api/clients', body=body, query=query);
    }

    delete() {
        return this.utilsApi.delete(true, '/api/clients');
    }

    verificationEmail( body:any, query:any = {} ){
        return this.utilsApi.post(true, '/api/clients/verification', body=body, query=query);
    }

    getToken( query:any = {} ){
        return this.utilsApi.get(true, '/api/token', query=query);
    }

    changePassword(body: any={}){
        return this.utilsApi.post(true, '/api/clients/password', body);
    }

    forgetPassword(body: any={}){
        return this.utilsApi.post(false, '/api/clients/password/forget', body);
    }

    recoverPassword(body: any={}){
        return this.utilsApi.post(false, '/api/clients/password/recover', body);
    }
}
