import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class UtilsApi {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    get( isAuth: boolean, path: string, query={} ) {
        let headers = new HttpHeaders();

        if( isAuth ){
            let token = this.authService.getAuthorization();

            if( !token ){
                return new Observable<any>( subscriber => {
                    subscriber.error( { message:"Necesitas estar logueado para realizar esta acción" } );
                } );
            }

            headers = headers.append("Authorization", token);
        }

        return this.http.get<any>(environment.urlApi + AuthService.lang + path, { headers, params:query } ).pipe(
            map(data => data),
            catchError(this.handleError)
        );
    }

    post( isAuth: boolean, path: string, body={}, query={} ) {
        let headers = new HttpHeaders();

        if( isAuth ){
            let token = this.authService.getAuthorization();

            if( !token ){
                return  new Observable<any>( subscriber => {
                    subscriber.error( { message:"Necesitas estar logueado para realizar esta acción" } );
                } );
            }

            headers = headers.append("Authorization", token);
        }

        return this.http.post<any>(environment.urlApi + AuthService.lang + path, body, { headers, params:query } ).pipe(
            map(data => data),
            catchError(this.handleError)
        );
    }

    put( isAuth: boolean, path: string, body={}, query={} ) {
        let headers = new HttpHeaders();

        if( isAuth ){
            let token = this.authService.getAuthorization();

            if( !token ){
                return new Observable<any>( subscriber => {
                    subscriber.error( { message:"Necesitas estar logueado para realizar esta acción" } );
                } );
            }

            headers = headers.append("Authorization", token);
        }

        return this.http.put<any>(environment.urlApi + AuthService.lang + path, body, { headers, params:query } ).pipe(
            map(data => data),
            catchError(this.handleError)
        );
    }

    delete( isAuth: boolean, path: string, query={} ) {
        let headers = new HttpHeaders();

        if( isAuth ){
            let token = this.authService.getAuthorization();

            if( !token ){
                return new Observable<any>( subscriber => {
                    subscriber.error( { message:"Necesitas estar logueado para realizar esta acción" } );
                } );
            }

            headers = headers.append("Authorization", token);
        }

        return this.http.delete<any>(environment.urlApi + AuthService.lang + path, { headers, params:query } ).pipe(
            map(data => data),
            catchError(this.handleError)
        );
    }

    private handleError(res: HttpErrorResponse | any) {
        return new Observable<any>( subscriber => {
            subscriber.error((res !== undefined || res !== null) ?  res.error : 'Server error' );
        });
    }
}
