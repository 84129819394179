import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreloadingStrategyService implements PreloadingStrategy {

  static listRoute:any = {};
  static whiteRoute:any = ['home', 'login', 'terms', 'privacy', 'about', 'signup1', 'pricing']; //'faqs', 

  preload(route: Route, load: Function): Observable<any> {
    if( PreloadingStrategyService.whiteRoute.includes(route.path) ){
      return load();
    }else{
      PreloadingStrategyService.listRoute[<string>route.path] = load;
    }
    return of(null);
  }

  preLoadRoute(name:any){
    if(name in PreloadingStrategyService.listRoute){
      PreloadingStrategyService.listRoute[name]();
      delete PreloadingStrategyService.listRoute[name];
    }else{
      PreloadingStrategyService.whiteRoute.push(name);
    }
  }
}

export function PreLoad(pages:any):ClassDecorator{
    return function( constructor:any ){
      const inject = Injector.create([{provide:PreloadingStrategyService, deps:[]}]);
      const loader = inject.get(PreloadingStrategyService);

      const ngOnInit = constructor.prototype.ngOnInit;
      constructor.prototype.ngOnInit = function(...args: any){

        for( let name of pages )
          loader.preLoadRoute(name);

        if( ngOnInit ){
          ngOnInit.apply(this, args);
        }
      }
    }
}