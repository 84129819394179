import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {PushNotifications } from '@capacitor/push-notifications';
import { AuthService } from '../services/auth.service';
import { FirebaseService } from '../services/firebase.service';
import { NotificationsApi } from '../services/api/notifications.api';
//const { PushNotifications } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  private static instance: PushNotificationService;

  constructor(
    private authService: AuthService,
    private notificationsApi: NotificationsApi,
    private firebaseService: FirebaseService,
  ) {
  }

  register(){
    if( !Capacitor.isPluginAvailable('PushNotifications') ){
      console.log('PushNotifications no Avaible');
      this.firebaseService.register();
      this.firebaseService.listen();
      return;
    }

    console.log('Initializing PushNotificationService');
    PushNotifications.requestPermissions().then(result => {
      if (result.receive == 'granted') {
        PushNotifications.register();
      }
    });

    PushNotifications.addListener('registration',
      (token) => {
        console.log('Push registration success, token: ' + token.value);
        this.firebaseService.saveToken(token.value);
      }
    );

    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    PushNotifications.addListener('pushNotificationReceived',
      (notification) => {
        console.log('Push received: ' + JSON.stringify(notification));
        try{
          this.authService.setUser({"countNotification": parseInt(notification.data.countNotification) });
        }catch{}
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }
}
