import { Injectable } from '@angular/core';
import { NavController, MenuController } from '@ionic/angular';
import { StorageService } from './storage.service';
import { GaService } from './ga.service';
import { environment } from '../../environments/environment';
import { Subject, lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { interval }           from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  storage:any=null;
  IdInvitado!:string;
  email:string = "Invitado";
  profilePicture:string | null = null;
  static timeDiffServer:number | null = null;
  static lang:string = 'es';
  dateGlobal:any;
  firebase:any;

  public replaySubject:Subject<any> = new Subject<any>();
  listTimeIntervalAuth:any = [];
  LIMIT_TIME_INTERVAL_AUTH:number = 600000;

  constructor(
    private storageService: StorageService,
    private navCtrl:NavController,
    private ga:GaService,
    public translate: TranslateService,
    public menuCtrl:MenuController,
    private titleService: Title
  ) { 
    this.storageService.getString('lang').then( val => {
      AuthService.lang = ( val.value )? val.value : 'es';
    })

    this.storageService.getObject('dateGlobal').then( val => {
      if( !val )
        this.setDateGlobal( new Date(), 'monthly');
      else
        this.dateGlobal = val;
    })
   }

  async setTitlePage(title: string | null = null){
    let clientId = this.isLoggedIn?this.email:this.IdInvitado; 
    if( title ){
      let translateTitle = await lastValueFrom(this.translate.get(title).pipe());
      this.titleService.setTitle('FinPlan Inteligente / ' + translateTitle);
      this.ga.tracking( clientId, 'pageview', title, 'FinPlan Inteligente / ' + translateTitle);
    }else{
      this.titleService.setTitle('FinPlan Inteligente');
      this.ga.tracking( clientId, 'pageview', 'home',  "FinPlan Inteligente");
    }
  }

  login(data: any) {
    let self = this;
    return new Promise( (resolve, reject) => {
        if( !data || !data.token )
          return reject(0);
        
        self.storage = data;
        self.isLoggedIn = true;
        self.email = data.data.email;
        self.profilePicture = data.data.image;
        self.storageService.setObject( 'storage', data );
        self.setDateGlobal( new Date(), 'monthly');
        self.replaySubject.next({'type':'updateUser'});
        
        if( !self.storage.data.completeSignUp && self.storage.data.currentSignupStep <= 6 ){
              this.navCtrl.navigateRoot(['signup'+String(self.storage.data.currentSignupStep)]);
              return resolve(self.storage.data.currentSignupStep);
        }

        self.setLang( data.data.lang );

        return resolve(0);
    });
  }

  initLang(){
    let self = this;
    this.storageService.getString('lang').then( val => {
      AuthService.lang = val.value? val.value : 'es';
      this.translate.setDefaultLang('en');
      this.translate.use(AuthService.lang);
    } );
  }
  async setLang(lang: string){
    AuthService.lang = lang;
    this.translate.use(lang);
    await this.storageService.setString('lang', lang);
  }

  getLang(){
    return AuthService.lang;
  }

  setUser( data: any ){
    if( !this.isLoggedIn ) return;
    for( let key in data )    
      this.storage.data[key] = data[key];
    this.storageService.setObject( 'storage', this.storage );
    this.replaySubject.next({'type':'updateUser'});
    this.case({ 'url': window.location.pathname });
  }

  case(next: any){
    let pathSingup = next.url.toString().indexOf('signup') != -1;
    let pathPayment = next.url.toString().indexOf('payment') != -1;
    let pathPlan = next.url.toString().indexOf('plan') != -1;
    let pathWarn= next.url.toString().indexOf('warn') != -1;
    let pathSubscription= next.url.toString().indexOf('subscription') != -1;
    let pathUnsubscribe= next.url.toString().indexOf('unsubscribe') != -1;

    if( environment.isApp ){
      if( pathSingup || pathPayment || pathPlan ){
        this.storageService.setString('warnExpired', this.storage.data.expired);
        this.logout();
        this.navCtrl.navigateRoot(['warn']);
        return false;
      }
      if( pathWarn )
        return true;
    }

    //No ha completado los 6 pasos del registro
    if( !this.storage.data.completeSignUp || this.storage.data.currentSignupStep <= 6 ){
      if( !pathSingup && !pathUnsubscribe ){
        this.navCtrl.navigateRoot(['signup'+String(this.storage.data.currentSignupStep)]);
        return false;
      }
      return true;
    }

    //Expired Subciptions
    let now = new Date().getTime() - (<number>AuthService.timeDiffServer);
    if( !this.storage || !this.storage.data.expired || this.storage.data.expired=='' || new Date(this.storage.data.expired + "T23:59:59").getTime() < now){
      
      //Esto debe ser descomentado y devolver el valor en el return para la suscripciones de pago
      /*if( !pathPayment && !pathPlan && !pathSingup ){
        this.navCtrl.navigateRoot(['plan']);
        return false;
      }*/
      return true;
    }else{
      if( (pathPayment || pathPlan) && !this.storage.data.trial ){
        this.navCtrl.navigateRoot(['dashboard']);
        return false;
      }

      if( pathSubscription && this.storage.data.trial ){
        this.navCtrl.navigateRoot(['dashboard']);
        return false;
      }
    }

    return true;
  }

  logout() {
    this.menuCtrl.enable(false);
    this.storageService.removeItem( 'storage' );
    this.storageService.removeItem( 'dateGlobal' );
    this.storageService.removeItem( 'setTimeIntervalAuth' );
    delete this.storage;
    this.isLoggedIn = false;
    this.email = "Invitado";
    this.profilePicture = null;
    this.clearTimeIntervalAuth();
    this.replaySubject.next({'type':'updateUser'});
    return true;
  }

  getToken() {
    let self = this;
    return this.storageService.getObject( 'storage' ).then(
      data => {
        if(!data) return;

        this.storage = data;
        self.email = data.data.email;
        self.profilePicture = data.data.image;

        if(this.storage.token != null ) {
          this.isLoggedIn=true;
        } else {
          this.isLoggedIn=false;
        }
      },
      error => {
        this.storage = null;
        this.isLoggedIn=false;
      }
    );
  }

  getAuthorization( ){
    if( !this.storage ){
      
     return null;
    }

    if( this.storage.expiresToken && this.storage.expiresToken> Date.now() ){
      this.setTimeIntervalAuth();
      return "Bearer "+ this.storage.token;
    }

    return null;
  }

  valideAuth(error: any){
    if( error && error.code && error.code == 401 ){
      this.navCtrl.navigateRoot('home');
      this.logout()
    }
  }

  notAuth(){
    this.getToken().then(() => {
      if( this.isLoggedIn && this.getAuthorization( ) )
        this.navCtrl.navigateRoot('/dashboard');
    },
    err=>{
    });
  }

  setDateGlobal(dat:any, typ: string){
    this.dateGlobal = {'date':dat, 'type':typ};
    this.storageService.setObject( 'dateGlobal', this.dateGlobal );
  }

  setFirebase(token: string){
    this.firebase = token;
    this.storageService.setString('firebase', token);
  }

  getFirebase(){
    return this.storageService.getString('firebase');
  }

  setTimeIntervalAuth(limit_time = 600000){
    if( !this.isLoggedIn ) return;
    this.validTimeIntervalAuth();
    this.clearTimeIntervalAuth();
    this.storageService.setObject("setTimeIntervalAuth", { "time":new Date().getTime() + limit_time } );
    let sub = interval(limit_time).subscribe( x =>{
      this.validTimeIntervalAuth();
    } );
    this.listTimeIntervalAuth.push( sub );
  }

  validTimeIntervalAuth(){
    this.storageService.getObject( 'setTimeIntervalAuth' ).then(
      data => {
        if(!data || !this.isLoggedIn || data.time > new Date().getTime()+10  ) return; 
        this.logout();
        this.navCtrl.navigateRoot('home');
    });
  }

  clearTimeIntervalAuth(){
    for( let x of this.listTimeIntervalAuth )
      x.unsubscribe();
  }

  setRef(ref: string){
    this.storageService.setString('ref', ref);
  }

  getRef(){
    return this.storageService.getString('ref');
  }
}
