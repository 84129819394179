
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { LogoFinplanComponent } from './logo-finplan/logo-finplan.component';
import { HeaderBackComponent } from './header-back/header-back.component';
import { HeaderComponent } from './header/header.component';
import { MenuDesktopComponent } from './menu-desktop/menu-desktop.component';
import { MenuUserComponent } from './menu-user/menu-user.component';
import { WizardSingupComponent } from './wizard-singup/wizard-singup.component';
import { FastLinkDialogComponent } from './fast-link-dialog/fast-link-dialog.component';
import { FooterComponent } from './footer/footer.component';
import { MenuModalComponent } from './menu-modal/menu-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { HomeParalaxDirective } from './home-paralax.directive';
import { MenuLangComponent } from './menu-lang/menu-lang.component';
import { FilterPeriodComponent } from './filter-period/filter-period.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationViewComponent } from './notification-view/notification-view.component';
import { TransactionViewComponent } from './transaction-view/transaction-view.component';
import { RuleFormComponent } from './rule-form/rule-form.component';
import { NotSingupComponent } from './not-singup/not-singup.component';
import { CarruselComponent } from './carrusel/carrusel.component';
import { SliderTextHomeComponent } from './slider-text-home/slider-text-home.component';
import { TimeAgoPipe } from '../pipes/time-ago.pipe';
import { Dec2Pipe } from '../pipes/dec2.pipe';
import { MaterialModule } from '../material.module';
import { CreditItemComponent } from './credit-item/credit-item.component';
import { LoanItemComponent } from './loan-item/loan-item.component';
import { BalanceItemComponent } from './balance-item/balance-item.component';

@NgModule({
  entryComponents: [
    MenuUserComponent,
    FastLinkDialogComponent,
    MenuLangComponent,
    NotificationsComponent,
    NotificationViewComponent,
    RuleFormComponent,
    TransactionViewComponent
  ],
  declarations: [
    LogoFinplanComponent,
    HeaderComponent,
    MenuDesktopComponent,
    HeaderBackComponent,
    MenuUserComponent,
    WizardSingupComponent,
    FastLinkDialogComponent,
    FooterComponent,
    MenuModalComponent,
    HomeParalaxDirective,
    MenuLangComponent,
    FilterPeriodComponent,
    NotificationsComponent,
    NotificationItemComponent,
    TimeAgoPipe,
    NotificationViewComponent,
    RuleFormComponent,
    NotSingupComponent,
    CarruselComponent,
    SliderTextHomeComponent,
    TransactionViewComponent,
    Dec2Pipe,
    CreditItemComponent,
    LoanItemComponent,
    BalanceItemComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  imports: [
      CommonModule ,
      IonicModule,
      TranslateModule,
      MaterialModule
  ],
  exports: [
    LogoFinplanComponent,
    HeaderComponent,
    MenuDesktopComponent,
    HeaderBackComponent,
    MenuUserComponent,
    WizardSingupComponent,
    FastLinkDialogComponent,
    FooterComponent,
    MenuModalComponent,
    TranslateModule,
    HomeParalaxDirective,
    MenuLangComponent,
    FilterPeriodComponent,
    NotificationsComponent,
    NotificationItemComponent,
    TimeAgoPipe,
    NotificationViewComponent,
    RuleFormComponent,
    NotSingupComponent,
    CarruselComponent,
    SliderTextHomeComponent,
    TransactionViewComponent,
    Dec2Pipe,
    CreditItemComponent,
    LoanItemComponent,
    BalanceItemComponent
  ]
})
export class ComponentsModule {}
