import { NgModule } from '@angular/core';


import {MatDatepickerModule} from '@angular/material/datepicker';

import {MatNativeDateModule} from '@angular/material/core';
import { MatSelectModule} from '@angular/material/select';
import { MatTooltipModule} from '@angular/material/tooltip';
@NgModule({
  imports: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTooltipModule ],
  exports: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTooltipModule ],
})
export class MaterialModule {}