import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AlertService } from '../services/alert.service';
import { NavController  } from '@ionic/angular';

/*@Injectable({
  providedIn: 'root'
})*/
export namespace AuthGuard {
  /*constructor(
    private navCtrl:NavController,
    private authService: AuthService,
    private alertService: AlertService,
  ) {}*/

  export const canActivate = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean => {
      return new Promise( (resolve, reject) => {

        const authService = inject(AuthService); 
        const navCtrl = inject(NavController);
        const alertService = inject(AlertService);

        authService.getToken().then(() => {
          let currentUser = authService.isLoggedIn;
          let authorization = authService.getAuthorization();
          
          if ( currentUser && authorization ){ 
            return resolve(authService.case(next));
          }

          if( currentUser && !authorization )
            authService.logout();

          navCtrl.navigateRoot(['home']);
          alertService.presentToast("Necesitas estar logueado para realizar esa acción");
          return resolve(false);
        },
        err=>{
          return resolve(false);
        });
    });
  }

  
  
}
