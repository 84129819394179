import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StringifyOptions } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class GaService {

  constructor(
      private http: HttpClient
  ) {
    }

  tracking(clientId: any, hitType: string, Page: string, Title: string){
      let headers = new HttpHeaders();
      let ga_url = "https://www.google-analytics.com/collect";
      // Create hit data
      let payloadData:any = {
        // Version Number
        v: 1,
        // Client ID
        cid: clientId,
        // Tracking ID
        tid: environment.gaTrackingID,
        // Hit Type
        t: hitType,
        // Event Category
        ec: Page,
        // // Event Action
        // ea: eventAction,
        // Event Label
        el: Title,
        dp: Page,
        dt: Title,
        dh: environment.urlBrowserApp
      };

      // Format hit data into URI
      var payloadString = Object.keys(payloadData)
      .filter(function(analyticsKey) {
        return payloadData[analyticsKey];
      })
      .map(function(analyticsKey) {
        return analyticsKey + '=' + encodeURIComponent(payloadData[analyticsKey]);
      })
      .join('&');

      return this.http.get<any>(ga_url, { headers, params:payloadData } ).pipe(
          map(data => data),
          catchError(this.handleError)
      )
  }

  private handleError(res: HttpErrorResponse | any) {
      return throwError(() => new Error(res.error || 'Server error'));
  }
}
